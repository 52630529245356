import emailjs from "emailjs-com";
import React, { useState } from "react";
import { callLink, contactNumber, whatsappLink } from "../data/contact";
const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  const klLocation = [
    "Bukit Jalil",
    "Sri Petaling",
    "Salak South",
    "OKR",
    "Taman Desa",
    "OUG",
    "Bangsar",
    "Mid Valley",
    "Jalan Pantai Dalam",
    "Setapak",
    "Setia Wangsa",
    "Jelatek",
    "Batu Caves",
    "Taman Midah",
    "Bandar Sri Permaisuri",
    "Taman Connaught",
    "Sentul",
    "Jalan Ipoh",
    "Jalan Kuching",
    "Jinjang Utara",
    "Kepong Baru",
    "Jalan Petaling",
    "Jalan Sultan Ismail",
    "Brickfield",
    "Mont Kiara",
    "Bukit Damansara",
    "Bandar Sri Damansara",
    "Bandar Manjalara",
    "Bukit Segar",
    "Jalan Ampang Hilir",
    "Taman Maluri",
    "Pandan Perdana",
    "Jalan Loke Yew",
    "Pudu",
    "TTDI",
    "Taman Keramat",
    "Melawati",
    "Putrajaya",
  ];
  const selangorLocation = [
    "Puchong",
    "Ara Damansara",
    "Kelana Jaya",
    "Bandar Utama",
    "Kota Damansara",
    "Kajang",
    "Bandar Mahkota Cheras",
    "Sri Kembangan",
    "Bandar Baru Ampang",
    "Bukit Serdang",
    "Sierramas West Sungai Buloh",
    "Taman Alam Impian Shah Alam",
    "Bangi",
    "Subang",
    "Subang Jaya",
    "Sunway",
    "Sea Park",
    "Ukay Perdana",
    "Cheras Batu 10",
    "Pandan Indah",
    "Taman Cempaka",
    "Taman Pandan Ria Subang",
    "Bukit Rahman Putra Sungai Buloh",
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    {
      /* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */
    }

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "YOUR_PUBLIC_KEY"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <h2>Our Service Area</h2>
          {/* <div className="col-md-8"> */}
          {/* <div className="row"> */}
          {/* <div className="section-title">
                <h2>Our Service Area</h2>
                <p>
                  Bukit Jalil
                  <br /> Sri Petaling
                  <br /> Salak South
                  <br /> OKR Taman Desa OUG Bangsar Mid Valley Jalan Pantai
                  Dalam Setapak Setia Wangsa Jelatek Batu Caves Taman Midah
                  Bandar Sri Permaisuri Taman Connaught Sentul Jalan Ipoh Jalan
                  Kuching Jinjang Utara Kepong Baru Jalan Petaling Jalan Sultan
                  Ismail Brickfield Mont Kiara Bukit Damansara Bandar Sri
                  Damansara Bandar Manjalara Bukit Segar Jalan Ampang Hilir
                  Taman Maluri Pandan Perdana Jalan Loke Yew Pudu TTDI Taman
                  Keramat Melawati Putrajaya
                </p>
              </div> */}
          {/* <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form> */}
          {/* </div> */}
          {/* </div> */}

          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Kuala Lumpur</h3>
              {klLocation.map((l, i) => {
                return <p key={i}>{l}</p>;
              })}
              <br />
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Selangor</h3>
              {selangorLocation.map((l, i) => {
                return <p key={i}>{l}</p>;
              })}
              <br />
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>
                <a href={callLink} style={{ color: "inherit" }}>
                  +{contactNumber}
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={callLink}>
                      <i className="fa fa-phone"></i>
                    </a>
                  </li>
                  <li>
                    <a href={whatsappLink}>
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Issaaf Kattan React Land Page Template. Design by{" "}
            <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a>
          </p>
        </div>
      </div> */}
    </div>
  );
};
